import React, { lazy, Suspense, useEffect, useState } from "react";
import { CpButton } from "canopy-styleguide!sofe";
import { getActiveTab } from "./tabs.helper.js";
import { getOldClientDrawer } from "clients-ui!sofe";

const OldClientDrawer = lazy(getOldClientDrawer);

export function OldClientDrawerButton({
  loggedInUser,
  client,
  refetchClient,
}: {
  loggedInUser: any;
  client: any;
  refetchClient: () => void;
}) {
  const activeTab = getActiveTab(loggedInUser?.role);
  const [showClientDrawer, setShowClientDrawer] = useState(false);

  useEffect(() => {
    // Drawer should only be visible on files tab
    activeTab !== "files" && setShowClientDrawer(false);
  }, [activeTab]);

  return (
    <>
      {activeTab === "files" && (
        <CpButton
          btnType="flat"
          icon="person-details"
          aria-label="View client details"
          className="cp-ml-16"
          onClick={() => setShowClientDrawer((prev) => !prev)}
        >
          Client Info
        </CpButton>
      )}
      <Suspense fallback={null}>
        <OldClientDrawer
          client={client}
          show={showClientDrawer}
          onClose={() => setShowClientDrawer(false)}
          refetchClient={refetchClient}
        />
      </Suspense>
    </>
  );
}
